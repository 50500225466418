import React, { SVGProps } from 'react';

export const BarChart = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 20 20" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.31253 2.9375C8.31253 2.00552 9.06805 1.25 10 1.25C10.932 1.25 11.6875 2.00552 11.6875 2.9375V17.0625C11.6875 17.9945 10.932 18.75 10 18.75C9.06805 18.75 8.31253 17.9945 8.31253 17.0625V2.9375ZM15.0626 6.6875C15.0626 5.75552 15.8181 5 16.7501 5C17.682 5 18.4376 5.75552 18.4376 6.6875V17.0625C18.4376 17.9945 17.682 18.75 16.7501 18.75C15.8181 18.75 15.0626 17.9945 15.0626 17.0625V6.6875ZM3.25 8.75C2.31802 8.75 1.5625 9.50552 1.5625 10.4375V17.0625C1.5625 17.9945 2.31802 18.75 3.25 18.75C4.18198 18.75 4.9375 17.9945 4.9375 17.0625V10.4375C4.9375 9.50552 4.18198 8.75 3.25 8.75Z"
        fill="currentColor"
      />
    </svg>
  );
};
